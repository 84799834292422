<template>
    <div>
      <ParallaxComponent 
        :selectedImage="selectedImage"
        :title="'Experiences'"
        :paragraph="'Experience the magic of the African wild with Acacia Wilderness Mweya where your experience begins right from the doorsteps of your room'"
      />
    
      <section>
        <article class="accommodation-body">
          <section>
            <article>
              <div class="room-accommodation-grid">
                <div>
                  <img src="../../assets/images/drives.webp" />
                </div>
                <div class="d-flex align-items-center">
                  <div>
                    <h2>Game Drives</h2>
                    <p>Enjoy your own private space amidst nature</p>
                    <p>
                      The game experience starts right from your door steps with animals grazing about in the compound, meanwhile it’s a short drive to the famous Kasenyi plains to catch up with more antelopes, lions, buffalos and many other animals. The channel tracks also gives you a rich experience of animals as they make their way to and fro the channel for water. Not forgetting how easy it is to make it to Ishasha sector for the tree climbing lions 

                    </p>
                  </div>
                </div>
              </div>
  
              <div class="room-accommodation-grid-reserve">
                <div class="d-flex align-items-center">
                  <div>
                    <h2>Bird Watching</h2>
                    <p>Perfect for friends, colleagues, or couples seeking a romantic retreat.</p>
                    <p>
                      The peninsula has a variety of bird species which makes it an interesting place for bird watching, with over 600 bird species, the park is the 6th highest diversity in the world and first in Africa. Mornings and afternoon hours are the best for this walk down to the lake shores with a birding view point that offers a perfect view of the Kazinga channel and L. Edward.


                    </p>
                  </div>
                </div>
                <div>
                  <img src="../../assets/images/experiences2.jpg" />
                </div>
              </div>
  
              <div class="room-accommodation-grid">
                <div>
                  <img src="../../assets/images/experiences5.jpg" />
                </div>
                <div class="d-flex align-items-center">
                  <div>
                    <h2>Mangoose tracking experience </h2>
                    
                    <p>Perfect for friends, colleagues, or couples seeking a romantic retreat.</p>
                    <p>
                      This offers you the opportunity to track and trail the mangooses with a ranger guide, it involves watching and learning the behaviors of these rodents, while doing this activity one also gets a chance to study about some birds and a few animals such as warthogs, waterbucks and so many others. This activity takes between 2 -3 hours but can be made shorter depending on your need schedule.

                    </p>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </article>
      </section>
  
      <!-- <section>
        <article class="experiences-gallery">
            <div class="acacia-container">
                <div class="text-center">
                    <h2>Experiences Gallery</h2>
                    <p>Capture the beauty</p>
                </div>
                <div class="experiences-gallery-grid">
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 17.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 18.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 19.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 22.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 23.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/large1.png" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/large2.png" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/lion.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 17.jpg" alt="">
                    </div>
                </div>
            </div>
        </article>
      </section> -->
    </div>
  </template>
  
  <script>
  import ParallaxComponent from "../../components/website/pallarax-component.vue";
  export default {
  components: {
    ParallaxComponent
  },

    data() {
      return {
        selectedImage: require("../../assets/images/fire-experience.webp"),
      };
    },
  };
  </script>
  
  <style scoped>
  .experiences-gallery {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .acacia-container {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }
  
  .accommodation-body{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .accommodation-body h2{
    color: #17351E;
    font-size: 2em;
    font-weight: 500;
  }
  
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .room-accommodation-grid > div{
    height: 80vh;
  }
  
  .room-accommodation-grid > div p{
    font-size: 1.2em;
  }
  
  .room-accommodation-grid > div img{
    height: 80vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .room-accommodation-grid > div:nth-child(2){
    padding: 50px;
  }
  
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .room-accommodation-grid-reserve > div{
    height: 80vh;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.2em;
  }
  
  .room-accommodation-grid-reserve > div img{
    height: 80vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .room-accommodation-grid-reserve > div:first-child{
    padding: 50px;
  }
  
  .experiences-gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .experiences-gallery-grid >div{
    width: 100%;
    height: 200px;
    background-color: #DDD0A9;
  }
  .experiences-gallery-grid >div img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #DDD0A9;
  }
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  
  .acacia-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid > div:first-child{
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img{
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    height: 100%;
  }
  .room-accommodation-grid-reserve > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid-reserve > div:nth-child(2){
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img{
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child{
    height: 100%;
    padding-top: 50px;
  }
  .room-accommodation-grid-reserve > div img{
    height: 50vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 0px;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 0px;
  }
  .room-accommodation-grid > div p{
    font-size: 1.0em;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.0em;
  }
  
  .accommodation-body{
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .accommodation-body h2{
    padding-top: 10px;
  }
  
  .experiences-gallery {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .experiences-gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .experiences-gallery-grid >div{
    width: 100%;
    height: 120px;
    background-color: #DDD0A9;
  }
  .experiences-gallery-grid >div img{
    width: 100%;
    height: 120px;
    object-fit: cover;
    background-color: #DDD0A9;
  }

}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .acacia-container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    grid-auto-flow: row;
  }
  .room-accommodation-grid > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid > div:first-child{
    height: 40vh;
  }
  .room-accommodation-grid > div:first-child img{
    height: 40vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    height: 100%;
  }
  .room-accommodation-grid-reserve > div{
    margin-left: 5%;
    margin-right: 5%;
  }
  .room-accommodation-grid-reserve > div:nth-child(2){
    height: 40vh;
    grid-row: 1;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img{
    height: 40vh;
  }
  .room-accommodation-grid-reserve > div:first-child{
    height: 100%;
    padding-top: 50px;
  }
  .room-accommodation-grid-reserve > div img{
    height: 50vh;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 0px;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 0px;
  }
  .room-accommodation-grid > div p{
    font-size: 1.0em;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.0em;
  }
  
  .accommodation-body{
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .accommodation-body h2{
    padding-top: 10px;
  }
  
  .experiences-gallery {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .experiences-gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .experiences-gallery-grid >div{
    width: 100%;
    height: 120px;
    background-color: #DDD0A9;
  }
  .experiences-gallery-grid >div img{
    width: 100%;
    height: 120px;
    object-fit: cover;
    background-color: #DDD0A9;
  }

}

  </style>
  